import { useCallback, useState } from 'react';

import { hasHeroMatch } from '../../../telemetry/hero';

import { UsePageLoadTelemetryCallbackOptions, usePageLoadTelemetryCallback } from './usePageLoadTelemetryCallback';

export const useReportHeroComponentRenderedCallback = (options: UsePageLoadTelemetryCallbackOptions) => {
  const [hasHeroComponentRendered, setHeroComponentRendered] = useState(false);

  const pageLoadTelemetryCallback = usePageLoadTelemetryCallback(options);

  const { hero } = options;
  const reportHeroComponentRendered = useCallback(
    (component) => {
      if (hasHeroMatch(component, hero)) {
        setHeroComponentRendered(true);
      }
      pageLoadTelemetryCallback(component);
    },
    [hero, pageLoadTelemetryCallback]
  );

  return { reportHeroComponentRendered, hasHeroComponentRendered };
};
