import {
  setCurrentPageName as setCurrentPageNameInGlobalTelemetry,
  setPageLoadNumber as setPageLoadNumberInGlobalTelemetry,
} from '@ms/yammer-telemetry-support';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';

import { getPageLoadId, incrementPageLoadId } from '../../telemetry/pageLoadId';

import { PageTelemetryContextProviderProps } from './PageTelemetryContextProvider.types';
import { PageTelemetryContext, PageTelemetryContextValue, RouteProps } from './context';
import { useReportHeroComponentRenderedCallback } from './hooks';

interface ContextState extends RouteProps {
  readonly pathname?: string;
  readonly pageLoadId: number;
}

const PageTelemetryContextProvider: FC<PageTelemetryContextProviderProps> = ({ isRoutelessApp = false, children }) => {
  const hasRenderedFirstRoute = useRef(false);

  const [routeProps, setRouteProps] = useState<ContextState>({
    pathname: undefined,
    pageLoadId: getPageLoadId(),
    path: undefined,
    page: 'Unknown',
    hero: ['none'],
  });

  const setRoute = useCallback(
    (props: RouteProps, pathname: string) => {
      const hasRouteChanged =
        props.path !== routeProps.path ||
        props.page !== routeProps.page ||
        props.hero !== routeProps.hero ||
        pathname !== routeProps.pathname;

      if (!hasRenderedFirstRoute.current) {
        hasRenderedFirstRoute.current = true;
      } else if (hasRouteChanged) {
        incrementPageLoadId();
      }
      setRouteProps({ ...props, pathname, pageLoadId: getPageLoadId() });
    },
    [routeProps.hero, routeProps.page, routeProps.path, routeProps.pathname]
  );

  setCurrentPageNameInGlobalTelemetry(routeProps.page);
  setPageLoadNumberInGlobalTelemetry(routeProps.pageLoadId);

  const { hasHeroComponentRendered, reportHeroComponentRendered } = useReportHeroComponentRenderedCallback({
    ...routeProps,
    isRoutelessApp,
  });

  const value = useMemo<PageTelemetryContextValue>(
    () => ({
      page: routeProps.page,
      path: routeProps.path,
      hero: routeProps.hero,
      isRoutelessApp,
      setRoute,
      reportHeroComponentRendered,
      hasHeroComponentRendered,
    }),
    [
      routeProps.page,
      routeProps.path,
      routeProps.hero,
      isRoutelessApp,
      setRoute,
      reportHeroComponentRendered,
      hasHeroComponentRendered,
    ]
  );

  return <PageTelemetryContext.Provider value={value}>{children}</PageTelemetryContext.Provider>;
};

export default PageTelemetryContextProvider;
