import {
  cancelFirstPageLoadMeasure,
  reportFirstPageLoad,
  requestAnimationFrameAfterStyleAndLayout,
} from '@ms/yammer-data/dist/telemetry';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { getHeroStringForTelemetry, hasHeroMatch } from '../../../telemetry/hero';
import { getPageLoadId } from '../../../telemetry/pageLoadId';
import {
  cancelPageLoadTimingEvent,
  clearCachedAppPageLoadTracking,
  endAndReportCachedAppPageLoadTimingEventAfterStyleAndLayout,
  endAndReportPageLoadTimingEventAfterStyleAndLayout,
  isCachedAppPageLoad,
  startOrUpdatePageLoadTiming,
  updatePageLoadTimingEvent,
} from '../../../telemetry/pageLoadTiming';
import { HeroComponentName } from '../../AppPageRoutesWithTelemetry/AppPageRoutesWithTelemetry.types';
import { PageTelemetryContextValue } from '../context';

import { useDidVisibilityStateChange } from './useDidVisibilityStateChange';

export type UsePageLoadTelemetryCallbackOptions = Pick<
  PageTelemetryContextValue,
  'page' | 'path' | 'hero' | 'isRoutelessApp'
> & { readonly pathname?: string; readonly pageLoadId: number };
type UsePageLoadTelemetryCallback = (
  options: UsePageLoadTelemetryCallbackOptions
) => (component: HeroComponentName) => void;

/**
 * Reports FirstPageLoad, route-to-route PageLoad, and CachedFirstPageLoad times, handling abandon/cancel and timeout events.
 * Returns a callback to be called by any hero component which manages reporting after the appropriate browser paint delay.
 */
export const usePageLoadTelemetryCallback: UsePageLoadTelemetryCallback = ({
  pathname: pathnameProp,
  pageLoadId,
  path,
  page,
  hero,
  isRoutelessApp,
}) => {
  const { pathname: urlPathname } = useLocation();
  const pathname = pathnameProp || urlPathname;

  startOrUpdatePageLoadTiming({
    pageLoadId,
    eventProperties: {
      page,
      path,
      hero: getHeroStringForTelemetry(hero),
      urlPath: pathname,
      didVisibilityStateChange: false,
    },
  });

  const { didVisibilityStateChange, didVisibilityStateChangeRef } = useDidVisibilityStateChange({
    key: `${page}-${pageLoadId}`,
  });

  useEffect(
    function updatePendingPageLoadEventOnVisibilityChanges() {
      if (didVisibilityStateChange) {
        updatePageLoadTimingEvent({
          pageLoadId,
          eventProperties: { page, path, hero: getHeroStringForTelemetry(hero), didVisibilityStateChange },
        });
      }
    },
    [didVisibilityStateChange, hero, page, pageLoadId, path]
  );

  useEffect(() => {
    const didVisibilityStateChangeRefValue = didVisibilityStateChangeRef.current;

    return function cancelPendingPageLoadEventsOnRouteChange() {
      const hasRouteChanged = pageLoadId !== getPageLoadId();
      if (!hasRouteChanged) {
        return;
      }

      cancelFirstPageLoadMeasure({
        eventProperties: {
          page,
          path,
          hero: getHeroStringForTelemetry(hero),
          urlPath: pathname,
          didVisibilityStateChange: didVisibilityStateChangeRefValue,
        },
      });

      if (!isCachedAppPageLoad({ isRoutelessApp })) {
        cancelPageLoadTimingEvent({
          pageLoadId,
          eventProperties: {
            didVisibilityStateChange: didVisibilityStateChangeRefValue,
          },
        });
      }
    };
  }, [didVisibilityStateChangeRef, hero, isRoutelessApp, page, pageLoadId, path, pathname]);

  return useCallback(
    function reportHeroRendered(component) {
      if (!hasHeroMatch(component, hero)) {
        return;
      }

      const isCachedLoad = isCachedAppPageLoad({ isRoutelessApp });

      endAndReportPageLoadTimingEventAfterStyleAndLayout({
        pageLoadId,
        eventProperties: { didVisibilityStateChange: didVisibilityStateChangeRef.current, hero: component },
      });

      if (isCachedLoad) {
        endAndReportCachedAppPageLoadTimingEventAfterStyleAndLayout({
          eventProperties: {
            page,
            path,
            urlPath: pathname,
            hero: component,
            deepLink: true,
            didVisibilityStateChange: didVisibilityStateChangeRef.current,
          },
        });
        clearCachedAppPageLoadTracking({ isRoutelessApp });
      }

      requestAnimationFrameAfterStyleAndLayout(() => {
        reportFirstPageLoad({
          eventProperties: {
            page,
            path,
            hero: component,
            urlPath: pathname,
            didVisibilityStateChange: didVisibilityStateChangeRef.current,
          },
        });
      });
    },
    [hero, pageLoadId, didVisibilityStateChangeRef, isRoutelessApp, page, path, pathname]
  );
};
