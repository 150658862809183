import {
  endAndReportPerformanceMeasure,
  requestAnimationFrameAfterStyleAndLayout,
  startPerformanceMeasure,
} from '@ms/yammer-data/dist/telemetry';
import { PerformanceMeasure, ScenarioPerformanceData } from '@ms/yammer-telemetry';
import { PageLoadPerformanceEventProperties, PerformanceEventName } from '@ms/yammer-telemetry-support';

type PageLoadPerformanceEventName = Extract<PerformanceEventName, 'page_load' | 'cached_first_page_load'>;
interface PageLoadPerformanceMeasureStore {
  readonly measures: Record<string, ScenarioPerformanceData>;
  readonly completed: Record<string, true>;
  // eslint-disable-next-line @ms/prefer-readonly-properties
  mostRecentPageLoadMeasure?: PerformanceMeasure;
}

/**
 * TODO: Periodically clear out old measures to keep memory usage low
 * https://dev.azure.com/yammer/engineering/_sprints/taskboard/WebXP/engineering/2024/Q1/Q1S2?workitem=294525
 */
const store: PageLoadPerformanceMeasureStore = { measures: {}, completed: {} };

// Scenario timeout (30 seconds)
const pageLoadMeasureTimeout = 30_000;

interface PartialPageLoadPerformanceMeasureOptions {
  readonly eventName: PageLoadPerformanceEventName;
  readonly pageLoadId: number;
  readonly eventProperties?: Partial<PageLoadPerformanceEventProperties>;
}
type UpdatePageLoadPerformanceMeasure = (options: PartialPageLoadPerformanceMeasureOptions) => void;

interface PageLoadPerformanceMeasureOptions extends PartialPageLoadPerformanceMeasureOptions {
  readonly eventProperties?: PageLoadPerformanceEventProperties;
}

type StartPageLoadPerformanceMeasure = (options: PageLoadPerformanceMeasureOptions) => void;
/**
 * Starts a page performance measure event, which can be ended asynchronously by its pageLoadId. Events will cancel
 * and report as "timedOut" after 30 seconds. Uses startPerformanceMeasure and endScenarioPerformanceMeasure
 * internally to execute the performance measurements.
 * Returns true if successfully started, false if the event was already started.
 */
export const startPageLoadPerformanceMeasure: StartPageLoadPerformanceMeasure = ({
  eventName,
  pageLoadId,
  eventProperties = {},
}) => {
  const scenarioData = getPerformanceEntry(eventName, pageLoadId);
  if (scenarioData) {
    return;
  }

  // Start and store measure in scenario collection
  const measure: PerformanceMeasure = {
    ...startPerformanceMeasure(eventName),
    eventProperties,
  };
  const entry: ScenarioPerformanceData = {
    measure,
    timer: window.setTimeout(() => {
      endAndReportPageLoadPerformanceMeasureNowIfNotCompleted({
        eventName,
        pageLoadId,
        endedWithError: false,
        timedOut: true,
        interrupted: false,
      });
    }, pageLoadMeasureTimeout),
  };

  setPerformanceEventEntry(eventName, pageLoadId, entry);

  if (eventName === 'page_load') {
    store.mostRecentPageLoadMeasure = measure;
  }
};

/**
 * Updates event properties of an in-progress scenario performance measure. This allows for correct reporting of
 * dynamic properties (like didVisibilityStateChange) in case the event times out.
 */
export const updatePageLoadPerformanceMeasureEventProperties: UpdatePageLoadPerformanceMeasure = ({
  eventName,
  pageLoadId,
  eventProperties = {},
}) => {
  const entry = getPerformanceEntry(eventName, pageLoadId);

  if (entry) {
    const measure = {
      ...entry.measure,
      eventProperties: {
        ...(entry.measure.eventProperties as PageLoadPerformanceEventProperties),
        ...eventProperties,
      },
    };

    const updatedData: ScenarioPerformanceData = {
      ...entry,
      measure,
    };

    setPerformanceEventEntry(eventName, pageLoadId, updatedData);

    // istanbul ignore else
    if (eventName === 'page_load') {
      store.mostRecentPageLoadMeasure = measure;
    }
  }
};

export const startOrUpdatePageLoadPerformanceMeasureEventProperties: StartPageLoadPerformanceMeasure = ({
  eventName,
  pageLoadId,
  eventProperties,
}) => {
  const entry = getPerformanceEntry(eventName, pageLoadId);

  if (entry) {
    updatePageLoadPerformanceMeasureEventProperties({ eventName, pageLoadId, eventProperties });
  } else {
    startPageLoadPerformanceMeasure({ eventName, pageLoadId, eventProperties });
  }
};

interface EndAndReportPageLoadPerformanceMeasureOptions extends PartialPageLoadPerformanceMeasureOptions {
  readonly endedWithError: boolean;
  readonly timedOut?: boolean;
  readonly interrupted?: boolean;
}

type EndAndReportPageLoadPerformanceMeasure = (options: EndAndReportPageLoadPerformanceMeasureOptions) => void;

/**
 * Schedules a scenario performance event to be ended and reported after the full render + paint. Although the event
 * will be reported asynchronously to account for real user experience, it will be immediately tracked as "reported"
 * to prevent other async code from canceling or reporting this event.
 */
export const scheduleEndAndReportPageLoadPerformanceMeasureAfterStyleAndLayout: EndAndReportPageLoadPerformanceMeasure =
  (options) => {
    markPerformanceEventEntryAsCompleted(options.eventName, options.pageLoadId);

    requestAnimationFrameAfterStyleAndLayout(() => {
      endAndReportPageLoadPerformanceMeasureNow(options);
    });
  };

/**
 * Ends a scenario performance event and reports it to telemetry. It may be called automatically if a timeout is
 * reached (timedOut) or if a new scenario with the same event name is started (interrupted).
 */
export const endAndReportPageLoadPerformanceMeasureNowIfNotCompleted: EndAndReportPageLoadPerformanceMeasure = (
  options
) => {
  if (isPerformanceEventMeasureMarkedAsCompleted(options.eventName, options.pageLoadId)) {
    return;
  }

  endAndReportPageLoadPerformanceMeasureNow(options);
};

/**
 * Ends a scenario performance event and reports it to telemetry immediately, even if already marked as completed.
 */
const endAndReportPageLoadPerformanceMeasureNow: EndAndReportPageLoadPerformanceMeasure = ({
  eventName,
  pageLoadId,
  endedWithError,
  timedOut = false,
  interrupted = false,
  eventProperties = {},
}) => {
  const scenarioData = getPerformanceEntry(eventName, pageLoadId);
  if (scenarioData) {
    const measure = {
      ...scenarioData.measure,
      eventProperties: {
        ...scenarioData.measure.eventProperties,
        ...eventProperties,
        endedWithError,
        timedOut,
        interrupted,
      },
    };

    endAndReportPerformanceMeasure(measure);
    clearPerformanceEventEntry(eventName, pageLoadId, scenarioData);
    markPerformanceEventEntryAsCompleted(eventName, pageLoadId);

    // istanbul ignore else
    if (eventName === 'page_load') {
      store.mostRecentPageLoadMeasure = measure;
    }
  }
};

const getKey = (eventName: PageLoadPerformanceEventName, pageLoadId: number) => `${eventName}-${pageLoadId}`;

const clearPerformanceEventEntry = (
  eventName: PageLoadPerformanceEventName,
  pageLoadId: number,
  scenarioData: ScenarioPerformanceData
) => {
  window.clearTimeout(scenarioData.timer);

  // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
  delete store.measures[getKey(eventName, pageLoadId)];
};

type GetPerformanceEventEntry = (
  eventName: PageLoadPerformanceEventName,
  pageLoadId: number
) => ScenarioPerformanceData | undefined;
const getPerformanceEntry: GetPerformanceEventEntry = (eventName, pageLoadId) =>
  store.measures[getKey(eventName, pageLoadId)];

const setPerformanceEventEntry = (
  eventName: PageLoadPerformanceEventName,
  pageLoadId: number,
  entry: ScenarioPerformanceData
) => {
  store.measures[getKey(eventName, pageLoadId)] = entry;
};

const markPerformanceEventEntryAsCompleted = (eventName: PageLoadPerformanceEventName, pageLoadId: number) => {
  store.completed[getKey(eventName, pageLoadId)] = true;
};

interface ClearCompletedPerformanceEventMeasureOptions {
  readonly eventName: PageLoadPerformanceEventName;
  readonly pageLoadId: number;
}
type ClearCompletedPerformanceEventMeasure = (props: ClearCompletedPerformanceEventMeasureOptions) => void;
export const clearCompletedPerformanceEventMeasure: ClearCompletedPerformanceEventMeasure = ({
  eventName,
  pageLoadId,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
  delete store.completed[getKey(eventName, pageLoadId)];
};

type IsPerformanceEventMeasureMarkedAsCompleted = (
  eventName: PageLoadPerformanceEventName,
  pageLoadId: number
) => boolean;
const isPerformanceEventMeasureMarkedAsCompleted: IsPerformanceEventMeasureMarkedAsCompleted = (
  eventName,
  pageLoadId
) => store.completed[getKey(eventName, pageLoadId)] || false;

export const getMostRecentPageLoadMeasure = () => store.mostRecentPageLoadMeasure;
