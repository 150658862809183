import { LoadOnRenderOptions } from '@ms/yammer-libs-lazy';
import { PageName, UiComponentName } from '@ms/yammer-telemetry-support';

import { DataLoaderModulePromise } from '../PageDataLoadContextProvider';

/**
 * @deprecated Page routes must all properly configure telemetry. Please specify a valid UiComponentName and
 * ensure that specific component calls the reportHeroComponentRendered() hook. Full routing instructions here:
 * https://dev.azure.com/yammer/frontend/_git/yammer-clients?path=/projects/modules/page-route-telemetry/README.md&version=GBmaster&_a=preview
 */
export type MissingHeroComponentName = 'none';
export type HeroComponentName = UiComponentName | MissingHeroComponentName;

interface BaseRoute {
  /**
   * The string path this page route will render. If nested as a subroute it should only specify the sub-path.
   */
  readonly path: string;

  /**
   * A function which returns a dynamic import of the page component, which should use a webpackChunkName comment.
   * The "Page" component this loads should render an AppLayout content template as its root element, however nested
   * subroutes should render a content component here instead.
   */
  readonly loader: LoadOnRenderOptions<unknown>['loader'];

  /**
   * A function which returns dynamic import of the data loader, which should use a webpackChunkName comment.
   * This data loader can prefetch data for that route while the page is being rendered.
   * This way when the page component loads, data is already present.
   */
  readonly dataLoader?: DataLoaderModulePromise;
}

interface BaseRouteWithTelemetry extends BaseRoute {
  /**
   * The unique name of this route/page.
   */
  readonly page: PageName;

  /**
   * Name of the page's hero component, used to report page load times.
   */
  readonly hero: HeroComponentName[];
}

export interface PageRoute extends BaseRouteWithTelemetry {
  /**
   * Setting to true will only match if the URL's path matches this path exactly,
   * rather than using React Router's default behavior of matching nested paths.
   */
  readonly exact?: boolean;
}

/**
 * A route object which will be rendered if no other child routes match the current URL.
 * Use this to either a) render a component which looks at query params, permissions, or any
 * other logic to determine a redirect route, or b) render an error page.
 */
export type FallbackredirectRoute = Pick<PageRoute, 'page' | 'hero' | 'loader'>;

interface BaseParentPageRoute extends BaseRoute {
  /**
   * The list of subroutes to be rendered into
   */
  readonly childRoutes: PageRoute[];
}

export interface ParentPageRouteWithFallbackRedirectPath extends BaseParentPageRoute {
  /**
   * A path a parent route will redirect to if the current URL doesn't match any of its child routes.
   * Use this if your redirect doesn't require any logic around permissions, query params, etc.
   */
  readonly fallbackRedirectPath: string;
}

export interface ParentPageRouteWithFallbackRedirectRoute extends BaseParentPageRoute {
  /**
   * The fallback route which handles your page's general redirect if the given URL doesn't match any other subroutes.
   */
  readonly fallbackRedirectRoute: FallbackredirectRoute;
}

export type ParentPageRoute = ParentPageRouteWithFallbackRedirectPath | ParentPageRouteWithFallbackRedirectRoute;

export type AppRoutes = (PageRoute | ParentPageRoute)[];

export interface AppPageRoutesWithTelemetryProps {
  /**
   * The list of routes supported by the app. This array should be a static variable created at the root of your module,
   * any dynamic permission checks or redirects should be handled in each route's component. If the array cannot be
   * statically created then it should be memoized.
   */
  readonly routes: AppRoutes;
}

export const parentRouteHasRedirectRoute = (
  parentRoute: ParentPageRoute
): parentRoute is ParentPageRouteWithFallbackRedirectRoute =>
  !!(parentRoute as ParentPageRouteWithFallbackRedirectRoute).fallbackRedirectRoute;
