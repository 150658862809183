export type LocaleCode =
  | 'en-US'
  | 'ar-SA'
  | 'zh-CN'
  | 'zh-TW'
  | 'ca-ES'
  | 'cs-CZ'
  | 'da-DK'
  | 'nl-NL'
  | 'fi-FI'
  | 'fr-FR'
  | 'de-DE'
  | 'el-GR'
  | 'he-IL'
  | 'hu-HU'
  | 'id-ID'
  | 'it-IT'
  | 'ja-JP'
  | 'ko-KR'
  | 'nb-NO'
  | 'pl-PL'
  | 'pt-BR'
  | 'pt-PT'
  | 'ro-RO'
  | 'ru-RU'
  | 'es-ES'
  | 'sv-SE'
  | 'th-TH'
  | 'tr-TR'
  | 'uk-UA';

export interface LanguageOption {
  readonly name: string;
  readonly code: LocaleCode;
  readonly key: string;
}

export const languages: LanguageOption[] = [
  {
    name: 'English',
    code: 'en-US',
    key: 'english',
  },
  {
    name: 'العربية',
    code: 'ar-SA',
    key: 'arabic',
  },
  {
    name: '中文(简体)',
    code: 'zh-CN',
    key: 'chinese_simplified',
  },
  {
    name: '中文(繁體)',
    code: 'zh-TW',
    key: 'chinese_traditional',
  },
  {
    name: 'Català',
    code: 'ca-ES',
    key: 'catalan',
  },
  {
    name: 'Čeština',
    code: 'cs-CZ',
    key: 'czech',
  },
  {
    name: 'Dansk',
    code: 'da-DK',
    key: 'danish',
  },
  {
    name: 'Nederlands',
    code: 'nl-NL',
    key: 'dutch',
  },
  {
    name: 'Suomi',
    code: 'fi-FI',
    key: 'finnish',
  },
  {
    name: 'Français',
    code: 'fr-FR',
    key: 'french',
  },
  {
    name: 'Deutsch',
    code: 'de-DE',
    key: 'german',
  },
  {
    name: 'Ελληνικά',
    code: 'el-GR',
    key: 'greek',
  },
  {
    name: 'עברית',
    code: 'he-IL',
    key: 'hebrew',
  },
  {
    name: 'Magyar',
    code: 'hu-HU',
    key: 'hungarian',
  },
  {
    name: 'Indonesia',
    code: 'id-ID',
    key: 'indonesian',
  },
  {
    name: 'Italiano',
    code: 'it-IT',
    key: 'italian',
  },
  {
    name: '日本語',
    code: 'ja-JP',
    key: 'japanese',
  },
  {
    name: '한국어',
    code: 'ko-KR',
    key: 'korean',
  },
  {
    name: 'Norsk (bokmål)',
    code: 'nb-NO',
    key: 'norwegian',
  },
  {
    name: 'Polski',
    code: 'pl-PL',
    key: 'polish',
  },
  {
    name: 'Português (Brasil)',
    code: 'pt-BR',
    key: 'portugese_brazil',
  },
  {
    name: 'Português (Portugal)',
    code: 'pt-PT',
    key: 'portugese_portugal',
  },
  {
    name: 'Română',
    code: 'ro-RO',
    key: 'romanian',
  },
  {
    name: 'Русский',
    code: 'ru-RU',
    key: 'russian',
  },
  {
    name: 'Español',
    code: 'es-ES',
    key: 'spanish',
  },
  {
    name: 'Svenska',
    code: 'sv-SE',
    key: 'swedish',
  },
  {
    name: 'ภาษาไทย',
    code: 'th-TH',
    key: 'thai',
  },
  {
    name: 'Türkçe',
    code: 'tr-TR',
    key: 'turkish',
  },
  {
    name: 'Українська',
    code: 'uk-UA',
    key: 'ukrainian',
  },
];

export const getLanguageOption = (code: LocaleCode) =>
  languages.find((language: LanguageOption) => language.code === code);
