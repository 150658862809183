import { PageLoadPerformanceEventProperties } from '@ms/yammer-telemetry-support';

import { isCachedAppPageLoadUrlHashPresent, removeCachedAppLoadHashFromUrl } from './cachedAppLoadUrlHash';
import {
  clearCompletedPerformanceEventMeasure,
  endAndReportPageLoadPerformanceMeasureNowIfNotCompleted,
  getMostRecentPageLoadMeasure,
  scheduleEndAndReportPageLoadPerformanceMeasureAfterStyleAndLayout,
  startOrUpdatePageLoadPerformanceMeasureEventProperties,
  startPageLoadPerformanceMeasure,
  updatePageLoadPerformanceMeasureEventProperties,
} from './pageLoadTelemetryReporting';

interface PartialPageLoadTimingEventWithLoadIdOptions {
  readonly pageLoadId: number;
  readonly eventProperties: Partial<PageLoadPerformanceEventProperties>;
}
type PartialPageLoadTimingEventWithLoadId = (props: PartialPageLoadTimingEventWithLoadIdOptions) => void;

interface PageLoadTimingEventWithLoadIdOptions extends PartialPageLoadTimingEventWithLoadIdOptions {
  readonly eventProperties: PageLoadPerformanceEventProperties;
}
type PageLoadTimingEventWithLoadId = (props: PageLoadTimingEventWithLoadIdOptions) => void;

export const startPageLoadTiming: PageLoadTimingEventWithLoadId = ({ pageLoadId, eventProperties }) => {
  startPageLoadPerformanceMeasure({
    eventName: 'page_load',
    pageLoadId,
    eventProperties,
  });
};

export const startOrUpdatePageLoadTiming: PageLoadTimingEventWithLoadId = ({ pageLoadId, eventProperties }) => {
  startOrUpdatePageLoadPerformanceMeasureEventProperties({
    eventName: 'page_load',
    pageLoadId,
    eventProperties,
  });
};

export const updatePageLoadTimingEvent: PartialPageLoadTimingEventWithLoadId = ({ pageLoadId, eventProperties }) => {
  updatePageLoadPerformanceMeasureEventProperties({
    eventName: 'page_load',
    pageLoadId,
    eventProperties,
  });
};

export const endAndReportPageLoadTimingEventAfterStyleAndLayout: PartialPageLoadTimingEventWithLoadId = ({
  pageLoadId,
  eventProperties,
}) => {
  scheduleEndAndReportPageLoadPerformanceMeasureAfterStyleAndLayout({
    eventName: 'page_load',
    pageLoadId,
    eventProperties,
    endedWithError: false,
  });
};

export const cancelPageLoadTimingEvent: PartialPageLoadTimingEventWithLoadId = ({ pageLoadId, eventProperties }) => {
  endAndReportPageLoadPerformanceMeasureNowIfNotCompleted({
    eventName: 'page_load',
    pageLoadId,
    eventProperties,
    endedWithError: false,
    interrupted: true,
  });
};

const cachedAppPageLoadId = -1;

interface ClearCachedAppPageLoadTrackingProps {
  readonly isRoutelessApp: boolean;
}
type ClearCachedAppPageLoadTracking = (props: ClearCachedAppPageLoadTrackingProps) => void;
export const clearCachedAppPageLoadTracking: ClearCachedAppPageLoadTracking = ({ isRoutelessApp }) => {
  if (isRoutelessApp) {
    return;
  }

  history.replaceState(null, '', removeCachedAppLoadHashFromUrl(location.href));
};

interface IsCachedAppPageLoadProps {
  readonly isRoutelessApp: boolean;
}
type IsCachedAppPageLoad = (props: IsCachedAppPageLoadProps) => boolean;
export const isCachedAppPageLoad: IsCachedAppPageLoad = ({ isRoutelessApp }) => {
  if (isRoutelessApp) {
    return false;
  }

  return isCachedAppPageLoadUrlHashPresent();
};

export const startCachedAppPageLoadTiming = () => {
  clearCachedAppPageLoadTiming();

  startPageLoadPerformanceMeasure({
    eventName: 'cached_first_page_load',
    pageLoadId: cachedAppPageLoadId,
  });
};

const clearCachedAppPageLoadTiming = () => {
  cancelCachedAppPageLoadTimingEvent();
  clearCompletedPerformanceEventMeasure({
    eventName: 'cached_first_page_load',
    pageLoadId: cachedAppPageLoadId,
  });
};

interface EndCachedAppPageLoadTimingEventOptions {
  readonly eventProperties: Partial<PageLoadPerformanceEventProperties>;
}
type EndCachedAppPageLoadTimingEvent = (props?: EndCachedAppPageLoadTimingEventOptions) => void;

export const endAndReportCachedAppPageLoadTimingEventAfterStyleAndLayout: EndCachedAppPageLoadTimingEvent = (props) => {
  scheduleEndAndReportPageLoadPerformanceMeasureAfterStyleAndLayout({
    eventName: 'cached_first_page_load',
    pageLoadId: cachedAppPageLoadId,
    eventProperties: {
      ...getMostRecentPageLoadMeasure()?.eventProperties,
      ...props?.eventProperties,
    },
    endedWithError: false,
  });
};

const cancelCachedAppPageLoadTimingEvent: EndCachedAppPageLoadTimingEvent = (props) => {
  endAndReportPageLoadPerformanceMeasureNowIfNotCompleted({
    eventName: 'cached_first_page_load',
    pageLoadId: cachedAppPageLoadId,
    eventProperties: props?.eventProperties,
    interrupted: true,
    endedWithError: false,
  });
};
