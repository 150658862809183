/**
 * CAUTION
 * We are hoping to remove this file so you should not extend it further.
 * A global list doesn't scale well and represents an inversion of dependency.
 * Please add your error boundary names close to where they are defined.
 */

import { ComponentType } from 'react';

export type ErrorComponent = ComponentType<{ readonly title?: string; readonly error?: unknown }>;

export enum ErrorBoundaryName {
  Achievements = 'Achievements',
  AllFeed = 'AllFeed',
  AllTopics = 'AllTopics',
  AnnouncementNotificationBar = 'AnnouncementNotificationBar',
  AnswersAnalytics = 'AnswersAnalytics',
  AnswersCopilot = 'AnswersCopilot',
  App = 'App',
  AppPageRoutesWithTelemetry = 'AppPageRoutesWithTelemetry',
  AskMeAnything = 'AskMeAnything',
  AttachableLinkFeed = 'AttachableLinkFeed',
  AudienceAnalytics = 'AudienceAnalytics',
  BookmarkFeed = 'BookmarkFeed',
  CampaignAnalytics = 'CampaignAnalytics',
  CampaignCreateModalBoundary = 'CampaignCreateModalBoundary',
  CampaignEditModalBoundary = 'CampaignEditModalBoundary',
  CampaignFeed = 'CampaignFeed',
  CampaignHeader = 'CampaignHeader',
  CampaignSidebarSponsors = 'CampaignSidebarSponsors',
  CampaignSidebarOrganizers = 'CampaignSidebarOrganizers',
  CampaignEntityBoundary = 'CampaignEntityBoundary',
  Card = 'Card',
  ComunityAnalytics = 'ComunityAnalytics',
  ComunityExpertMembers = 'ComunityExpertMembers',
  CuratedThreadList = 'CuratedThreadList',
  DirectMessagePublisher = 'DirectMessagePublisher',
  DiscoveryFeed = 'DiscoveryFeed',
  DiscoveryNetworkQuestionFeed = 'DiscoveryNetworkQuestionFeed',
  DraftFeed = 'DraftFeed',
  DraftThread = 'DraftThread',
  EditPublisher = 'EditPublisher',
  EditTeamsMeeting = 'EditTeamsMeeting',
  EventAttendeeInfoBar = 'EventAttendeeInfoBar',
  EventCard = 'EventCard',
  EventCreationModalBoundary = 'EventCreationModalBoundary',
  EventPublisher = 'EventPublisher',
  EventsDiscoveryBanner = 'EventsDiscoveryBanner',
  GroupEvents = 'GroupEvents',
  EventEditModalBoundary = 'EventEditModalBoundary',
  GroupLiveEventsBanner = 'GroupLiveEventsBanner',
  FeaturedTopics = 'FeaturedTopics',
  FollowedTopics = 'FollowedTopics',
  FollowNotificationsPickerButton = 'FollowNotificationsPickerButton',
  FlightControlPanel = 'FlightControlPanel',
  GroupAdminStepper = 'GroupAdminStepper',
  GlobalAnswersAnalytics = 'GlobalAnswersAnalytics',
  GroupCreateModalBoundary = 'GroupCreateModalBoundary',
  GroupEditModalBoundary = 'GroupEditModalBoundary',
  GroupFeedNoNewPostsBanner = 'GroupFeedNoNewPostsBanner',
  GroupFeed = 'GroupFeed',
  GroupHeader = 'GroupHeader',
  GroupHeaderActions = 'GroupHeaderActions',
  GroupHeaderCompact = 'GroupHeaderCompact',
  GroupInsights = 'GroupInsights',
  GroupMembershipBulkUpload = 'GroupMembershipBulkUpload',
  GroupMemberCard = 'GroupMemberCard',
  GroupQnAInsights = 'GroupQnAInsights',
  GroupSidebar = 'GroupSidebar',
  GroupSidebarEvents = 'GroupSidebarEvents',
  GroupSidebarGoals = 'GroupSidebarGoals',
  GroupTopQuestionsFeed = 'GroupTopQuestionsFeed',
  GroupSimilarQuestionsFeed = 'GroupSimilarQuestionsFeed',
  HomeFeed = 'HomeFeed',
  InboxFeed = 'InboxFeed',
  InboxThread = 'InboxThread',
  LeaderContentStarterModal = 'LeaderContentStarterModal',
  LeadersDiscovery = 'LeadersDiscovery',
  LeaderEmployees = 'LeaderEmployees',
  LeaderHighlighted = 'LeaderHighlighted',
  LeadersManagement = 'LeadersManagement',
  LiveEvent = 'LiveEvent',
  LiveEventApp = 'LiveEventApp',
  LiveEventHeader = 'LiveEventHeader',
  LiveEventFeed = 'LiveEventFeed',
  LiveEventSidebar = 'LiveEventSidebar',
  LivePersonaCard = 'LivePersonaCard',
  M365CopilotAdminFRE = 'M365CopilotAdminFRE',
  M365CopilotSuggestion = 'M365CopilotSuggestion',
  M365CopilotSuggestedFeed = 'M365CopilotSuggestedFeed',
  M365CopilotSuggestedThread = 'M365CopilotSuggestedThread',
  MediaLightbox = 'MediaLightbox',
  MediaPost = 'MediaPost',
  MessageAttachments = 'MessageAttachments',
  MomentsList = 'MomentsList',
  MomentsListItem = 'MomentsListItem',
  MomentsModal = 'MomentsModal',
  MomentsPlayer = 'MomentsPlayer',
  MoreForYouFeed = 'MoreForYouFeed',
  MyLeaders = 'MyLeaders',
  MyTopics = 'MyTopics',
  NavigationCommunities = 'NavigationCommunities',
  NetworkAnalytics = 'NetworkAnalytics',
  NetworkQuestion = 'NetworkQuestion',
  NetworkQuestionAdmin = 'NetworkQuestionAdmin',
  NetworkQuestionInbox = 'NetworkQuestionInbox',
  NetworkSwitcherPanel = 'NetworkSwitcherPanel',
  NetworkThemeFeed = 'NetworkThemeFeed',
  NotificationItem = 'NotificationItem',
  NotificationPanel = 'NotificationPanel',
  OlderReplyPaginationControls = 'OlderReplyPaginationControls',
  PageUserIdResolver = 'PageUserIdResolver',
  PersonalAnalytics = 'PersonalAnalytics',
  PinnedThreads = 'PinnedThreads',
  PromotedFeed = 'PromotedFeed',
  ProposedTopics = 'ProposedTopics',
  PublisherAttachments = 'PublisherAttachments',
  PublisherTopicsBar = 'PublisherTopicsBar',
  PremiumTenantAdminFREs = 'PremiumTenantAdminFREs',
  RealtimeSubscription = 'RealtimeSubscription',
  RealtimeConnectionEventsSubscription = 'RealtimeConnectionEventsSubscription',
  Reply = 'Reply',
  ReplyPublisher = 'ReplyPublisher',
  RoutelessAppPagesWithTelemetry = 'RoutelessAppPagesWithTelemetry',
  SearchResult = 'SearchResult',
  SearchResultTopics = 'SearchResultTopics',
  SearchResultsList = 'SearchResultsList',
  SharedMessage = 'SharedMessage',
  SharedMessageTile = 'SharedMessageTile',
  ShareMessagePublisher = 'ShareMessagePublisher',
  SharePointConfigurationPane = 'SharePointConfigurationPane',
  ShareTeamsMeetingPublisher = 'ShareTeamsMeetingPublisher',
  StorylineHome = 'StorylineHome',
  Stepper = 'Stepper',
  StorylineAllFeed = 'StorylineAllFeed',
  StorylineDiscoveryFeed = 'StorylineDiscoveryFeed',
  StorylineFollowedFeed = 'StorylineFollowedFeed',
  StorylinePublisherOfficeIdRedirect = 'StorylinePublisherOfficeIdRedirect',
  SuggestedTopics = 'SuggestedTopics',
  SuiteHeader = 'SuiteHeader',
  TeachingBubbleWithSoftDismiss = 'TeachingBubbleWithSoftDismiss',
  TeamsLivePersonaCard = 'TeamsLivePersonaCard',
  TeamsMeeting = 'TeamsMeeting',
  Thread = 'Thread',
  ThreadAnalyticsV2 = 'ThreadAnalyticsV2',
  ThreadShare = 'ThreadShare',
  ThreadStarter = 'ThreadStarter',
  ThreadStarterPublisher = 'ThreadStarterPublisher',
  ThreadStarterRecommendationReason = 'ThreadStarterRecommendationReason',
  ThreadStarterTopicsBar = 'ThreadStarterTopicsBar',
  TopicFeatureModalBoundary = 'TopicFeatureModalBoundary',
  TopicSidebar = 'TopicSidebar',
  TopicUnified = 'TopicUnified',
  TrendingTopics = 'TrendingTopics',
  UniversalCreatePublisher = 'UniversalCreatePublisher',
  UserFeed = 'UserFeed',
  UserHeader = 'UserHeader',
  UserProfile = 'UserProfile',
  UserProfileCard = 'UserProfileCard',
  UserSidebar = 'UserSidebar',
  UserStorylineFeed = 'UserStorylineFeed',
  UserHeaderActions = 'UserHeaderActions',
  VivaAppBar = 'VivaAppBar',
  VivaAppBarMobile = 'VivaAppBarMobile',
  WritingAssistantChatMessage = 'WritingAssistantChatMessage',
}
